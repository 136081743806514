import styled from 'styled-components';
import presets from '../../presets/index';

export const FormContato = styled.form`
  width: 60%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 575px) {
    width: 100%;
  }

  & > div {
    width: 100%;
    display: flex;
    
    .left-form {
      width: 100%;
      margin-right: 15px;
    }

    .right-form {
      width: 100%;
      margin-left: 15px;

      .form-group {
        height: 100%;

        label {
          top: 10px;
        }
      }
    }

    .form-group {
      width: 100%;
      height: 50px;
      background-color: ${presets.blue};
      position: relative;
      margin-bottom: 30px;

      &:last-child {
        margin: 0;
      }

      &:focus-within label {
        font-weight: bold;
        color: ${presets.text_title};
        padding: 0 5px;
        left: 5px;
        top: -25px;
      }

      input:not(:placeholder-shown):not(:focus) ~ label {
        font-weight: bold;
        color: ${presets.text_title};
        padding: 0 5px;
        left: 5px;
        top: -25px;
      }

      textarea:not(:placeholder-shown):not(:focus) ~ label {
        font-weight: bold;
        color: ${presets.text_title};
        padding: 0 5px;
        left: 5px;
        top: -25px;
      }

      input, textarea {
        width: 100%;
        height: 100%;
        font-size: 16px;
        color: #FFF;
        background-color: transparent;
        outline: none;
        padding: 10px;
        border: 0;
        position: absolute;
      }

      textarea {
        resize: none;
      }

      label {
        color: #FFF;
        transition: all 0.5s;
        position: absolute;
        top: calc(50% - 12px);
        left: 10px;
      }
    }

    @media (max-width: 575px) {
      flex-direction: column;

      .left-form {
        margin-right: 0;
      }

      .right-form {
        margin-left: 0;

        .form-group {
          height: 300px;
        }
      }

      .form-group {
        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
  }

  .content-button {
    display: flex;
    justify-content: center;

    button {
      height: 40px;
      font-size: 18px;
      color: white;
      background-color: ${presets.orange};
      border: 0;
      padding: 0 25px;
      margin-top: 25px;
      border-radius: 25px;
      cursor: pointer;
    }
  }
`;