import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function TiposHospedagem() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Tipos de hospedagem de site e email (como escolher a ideal)</h3>
          <p>Existem três modalidades de hospedagem de site para que você escolha a forma mais adequada ao seu projeto.</p>
          <p>Nessa hora, <strong>não vai adiantar optar pela opção mais barata</strong>, se o seu site requerer recursos mais avançados (ou exclusivos).</p>
          <p>Isso quer dizer que, se o seu projeto possui muitas funcionalidades e muitos acessos precisará de um servidor com mais recursos. O contrário também pode acontecer: não justifica tem um site que demanda poucos recursos e com poucos acessos em uma máquina exclusiva.</p>
          <p>Leia os próximos tópicos para entender melhor.</p>
          <ul>
            <li>
              <strong>Hospedagem Compartilhada</strong><br />
              <p>Alguns sites institucionais não demandam grandes recursos para estar online e também possuem pouco tráfego.</p>
              <p>Para esses casos, é recomendado o uso de uma hospedagem compartilhada. Que nada mais é que um servidor robusto capaz de abrigar vários sites, compartilhando recursos de processamento, memória RAM, espaço em disco e banda da internet.</p>
              <p>O fato de ser um serviço mais barato que as outras modalidades, não faz dele uma opção ruim. Pelo contrário! <strong>Esses servidores possuem uma configuração potente, capaz de suportar considerável um número de acesso</strong>.</p>
            </li>
            <li>
              <strong>Servidor Virtual</strong><br />
              <p>O Cloud Computing ou VPS (Virtual Private Server) é o serviço intermediário da hospedagem de site. Indicado para aqueles que possuem um site que necessita de mais recursos e com possibilidade de grande número de acessos.</p>
              <p>Embora o Servidor Virtual esteja em uma mesma máquina que outras aplicações, não se divide os recursos.<strong>É possível montar um espaço virtual de acordo com as configurações (HD, memória RAM e Processamento) necessárias para o seu projeto</strong>.</p>
              <p>Além de alto desempenho e custo mais baixo que a hospedagem no servidor dedicado, o VPS é uma modalidade que permite alterar a configuração de acordo com o crescimento do seu site. Dessa forma, não é preciso pagar pelo que não está usando.</p>
            </li>
            <li>
              <strong>Servidor Dedicado</strong><br />
              <p>O <strong>servidor dedicado</strong> é uma máquina exclusiva para rodar as aplicações (servidor de arquivos, bancos de dados, Web, FTP, DNS e email) dos clientes com alta performance.</p>
              <p>É a alternativa mais robusta que existe e, consequentemente, mais cara. <strong>É indicada quando um site possui um nível muito grande de tráfego, conferindo a aplicação alto desempenho e segurança das informações</strong>.</p>
              <p>Ao optar por um servidor dedicado, é essencial definir com cuidado os recursos necessários para rodar suas aplicações. Escolher uma máquina muito potente pode fazê-lo deixar recursos subutilizados e o contrário não permitirá que a máquina responda como você espera.</p>
            </li>
          </ul>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default TiposHospedagem;