import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function Certificado() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Certificado SSL</h3>
          <p>O Certificado SSL (Secure Socket Layer) é uma tecnologia de criptografia que serve para proteger os seus dados na internet.</p>
          <p>Sabe aquele cadeadinho que aparece antes do “HTTPS://”? É ele. Qualquer site disponível na internet, <a href="">precisa dessa certificação para garantir a segurança dos dados.</a></p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Certificado;