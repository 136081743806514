import React, { useState } from 'react';
import { RiPlayFill } from 'react-icons/ri';

import { Duvidas, Collapse, ContentCollapse, Servicos, Painel, Videos, Contato } from './styles';
import { Container } from '../../styles/conteudo';

import Planejamento from '../../assets/img/conteudo/planejamento.svg';
import Servico1 from '../../assets/servicos/servicos_home_email_profissional.webp';
import Servico2 from '../../assets/servicos/servicos_home_hospedagem_de_site.webp';
import Servico3 from '../../assets/servicos/servicos_home_servidor_vps.webp';
import Servico4 from '../../assets/servicos/servicos_home_hospedagem_servidor.webp';
import ThumbVideo1 from '../../assets/videos/thumb-categoria-dominio.webp';
import ThumbVideo2 from '../../assets/videos/thumb-diferenca-dominio-hospedagem-site.webp';
import ThumbVideo3 from '../../assets/videos/thumb-fazer-transferencia-hospedagem-site.webp';
import ThumbVideo4 from '../../assets/videos/thumb-escolher-dominio-ideal.webp';
import ThumbVideo5 from '../../assets/videos/thumb-estrutura-dominio-como-registrar-dominio.webp';

import Banner from '../../components/Banner';
import Accordion from '../../components/Accordion';
import ModalVideo from '../../components/ModalVideo';
import Footer from '../../components/Footer';
import FormEnvioEmail from '../../components/FormEnvioEmail';

function Home() {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [urlVideo, setUrlVideo] = useState(false);

  return (
    <>
      {showVideoModal && <ModalVideo videoUrl={urlVideo} closeModal={() => setShowVideoModal(false)} />}

      <Banner />

      <div className="default">
        <Container>
          <h3 id="introducao">Como colocar sua empresa na internet</h3>
          <p className="destaque">O que é registro de domínio? E hospedagem de site? Por que eu preciso de um serviço de email? Como dar início a presença digital na internet?</p>
          <p>Para começar com o processo de entrada da sua empresa na internet, é importante escolher um nome para o seu negócio, decidir se pretende criar um website ou loja virtual e, claro, conhecer algumas empresas especializadas em hospedagem de site e registro de domínio para te auxiliar.</p>
          <div className="w-img">
            <img src={Planejamento} alt="Como colocar sua empresa na internet"/>
            <div>
              <p>Além das informações citadas acima, é preciso ter um planejamento minucioso para não errar nas suas ações (principalmente de marketing), como por exemplo:</p>
              <ul>
                <li>SEO para buscadores;</li>
                <li>Diversos canais online (site, blog, e-mail, mídias sociais) em sintonia;</li>
                <li>Conteúdo (texto, vídeo e imagem) relevante para gerar engajamento e fidelização dos clientes;</li>
              </ul>
            </div>
          </div>
          <p>Não é novidade que estar presente na internet tornou-se uma questão crucial para ampliar o negócio ou até mesmo mantê-lo estável.</p>
          <p>Ainda assim, uma pesquisa da <a href="https://www.cetic.br/" target="_blank">Cetic.br</a> divulgou que, das empresas que possuem internet, somente 55% tem um website e aqueles que não têm, consequentemente, acabam deixando de conquistar novos clientes.</p>
          <p>Se você precisa de um serviço de hospedagem de site - ou seja, um servidor capaz de guardar os arquivos do seu site e disponibilizá-los 24 horas na internet para que as pessoas encontrem seu negócio - continue lendo esse conteúdo e entenda um pouco mais sobre os processos para garantir sua presença na internet!</p>
        </Container>
      </div>

      <Duvidas>
        <div className="default">
          <Collapse>
            <h3 id="sobre-dominio">Tudo sobre registro de domínio</h3>

            <ContentCollapse>
              <div className="column">
                <Accordion
                  title="O que é domínio?"
                  content="O domínio é um nome que serve para identificar um endereço na internet."
                  link="/o-que-e-dominio"
                />
                <Accordion
                  title="Como registrar um domínio?"
                  content="Depois de escolher um nome de domínio e verificar se ele está disponível, é hora de fazer o registro desse endereço."
                  link="/como-registrar-um-dominio"
                />
                <Accordion
                  title="Como escolher o domínio ideal para o seu site"
                  content="Esse é o momento decisivo para conquistar visibilidade na rede. Pense bem na hora de escolher o nome do seu site, pois depois não será possível fazer alterações (a não ser que você crie outro, o que não é recomendável)."
                  link="/como-escolher-o-dominio-ideal-para-o-seu-site"
                />
                <Accordion
                  title="Por que usar vários domínios para o mesmo site?"
                  content="Tão importante quanto garantir a presença digital para o seu negócio, é ter visibilidade nos meios que escolher para apresentar o trabalho que desenvolve."
                  link="/por-que-usar-varios-dominios-para-o-mesmo-site"
                />
              </div>
              <div className="column">
                <Accordion
                  title="O que é registro de domínio?"
                  content="Esse é o primeiro passo para criar uma página na internet, será o endereço do seu site: www.nomedaempresa.com.br."
                  link="/o-que-e-registro-de-dominio"
                />
                <Accordion
                  title="Categorias de domínio"
                  content="Além da extensão mais comum “.com.br”, existem outras categorias de domínios que podem ser utilizadas de acordo com o seu tipo de negócio."
                  link="/categorias-de-dominio"
                />
                <Accordion
                  title="Por que ter um domínio personalizado?"
                  content="A resposta é simples: um e-mail profissional lhe confere muito mais credibilidade do que uma conta gratuita."
                  link="/por-que-ter-um-dominio-personalizado"
                />
                <Accordion
                  title="Domínio congelado"
                  content="Quando mantemos um endereço na internet (o domínio) temos que manter em dia a anualidade para que ele fique no ar. A falta de pagamento dessa tarifa pode fazer com que o seu domínio fique congelado."
                  link="/dominio-congelado"
                />
              </div>
            </ContentCollapse>
          </Collapse>

          <Collapse>
            <h3 id="sobre-hospedagem">Tudo sobre hospedagem de site</h3>

            <ContentCollapse>
              <div className="column">
                <Accordion
                  title="O que é hospedagem de site?"
                  content="A hospedagem de site é uma prestação de serviço online para armazenar, com segurança, qualquer tipo de informação em servidores devidamente configurado para este fim."
                  link="/o-que-e-hospedagem-de-site"
                />
                <Accordion
                  title="Tipos de servidor"
                  content="Existem dois tipos de opções de servidores para manter um site ou qualquer tipo de aplicação do seu negócio online. Na prática, a diferença é muito pequena, mas é interessante saber que existem opções."
                  link="/tipos-de-servidor"
                />
                <Accordion
                  title="Certificado SSL"
                  content="O Certificado SSL é uma tecnologia de criptografia que serve para proteger os seus dados na internet."
                  link="/certificado"
                />
                <Accordion
                  title="Hospedagem segura"
                  content="Garantir uma hospedagem segura para a sua página na internet é essencial para protegê-la contra invasões. Por isso, é preciso ficar atento a algumas questões."
                  link="/hospedagem-segura"
                />
              </div>
              <div className="column">
                <Accordion
                  title="Tipos de hospedagem de site e email"
                  content="Existem três modalidades de hospedagem de site para que você escolha a forma mais adequada ao seu projeto."
                  link="/tipos-de-hospedagem-de-site-e-email"
                />
                <Accordion
                  title="Principais recursos para uma hospedagem de site"
                  content="Os principais recursos em uma hospedagem de site são: espaço em disco (armazenamento), transferência (tráfego), segurança dos dados, certificados de segurança."
                  link="/principais-recursos-para-uma-hospedagem-de-site"
                />
                <Accordion
                  title="Importância do Backup"
                  content="Por mais segurança que exista nos serviços de hospedagem de site, é preciso se resguardar. Sendo assim, é essencial possuir uma cópia de segurança do seu projeto."
                  link="/importancia-do-backup"
                />
              </div>
            </ContentCollapse>
          </Collapse>

          <Collapse>
            <h3 className="subtitle-section">Glossário</h3>

            <ContentCollapse>
              <div className="column">
                <Accordion
                  title="Glossário"
                  content="Confira os principais termos técnicos e seus conceitos utilizados no ramo da hospedagem de sites."
                  link="/glossario"
                />
              </div>
            </ContentCollapse>
          </Collapse>
        </div>
      </Duvidas>

      <Servicos>
        <div className="default">
          <div className="content">
            <h3 id="servicos">Escolha a melhor solução para sua empresa</h3>
            <div className="wrap-painels">
              <Painel>
                <img src={Servico1} alt="E - mail Profissional" title="E - mail corporativo Task a partir de R$3,90"/>
                <div className="title">
                  <span>E-mail Profissional</span>
                </div>
                <div className="content">
                  <p>Com o e-mail corporativo da Task você tem mais segurança, privacidade e credibilidade para seu empreendimento.</p>
                  <hr />
                  <span>A partir de R$3,90</span>
                  <button onClick={() => window.open('https://www.task.com.br/email-corporativo')}>Conheça os planos</button>
                </div>
              </Painel>
              <Painel>
                <img src={Servico2} alt="Hospedagem de site" title="A melhor hospedagem de site para sua empresa"/>
                <div className="title">
                  <span>Hospedagem de site</span>
                </div>
                <div className="content">
                  <p>Estabilidade, segurança e uma equipe qualificada para te oferecer a melhor hospedagem de site para sua empresa.</p>
                  <hr />
                  <span>A partir de R$16,53</span>
                  <button onClick={() => window.open('https://www.task.com.br/hospedagem-de-site')}>Conheça os planos</button>
                </div>
              </Painel>
              <Painel>
                <img src={Servico3} alt="Servidor VPS" title="Servidor VPS com alto desempenho para o seu projeto"/>
                <div className="title">
                  <span>Servidor VPS</span>
                </div>
                <div className="content">
                  <p>O Servidor VPS utiliza recursos independentes de processador, espaço em disco, memória e sistema operacional, garantindo o desempenho necessário.</p>
                  <hr />
                  <span>A partir de R$77,00</span>
                  <button onClick={() => window.open('https://www.task.com.br/servidor-virtual')}>Monte seu plano</button>
                </div>
              </Painel>
              <Painel>
                <img src={Servico4} alt="Hospedagem de Servidor" title="Hospedagem de servidor para grandes projetos"/>
                <div className="title">
                  <span>Hospedagem de servidor</span>
                </div>
                <div className="content">
                  <p>O Servidor Dedicado é uma máquina exclusiva, recomendado para aplicações que necessitam de grande quantidade de recursos.</p>
                  <hr />
                  <span></span>
                  <button onClick={() => window.open('https://www.task.com.br/hospedagem-de-servidor')}>Solicite uma proposta</button>
                </div>
              </Painel>
            </div>
          </div>
        </div>
      </Servicos>

      <Videos>
        <div className="default">
          <h3 id="videos">Aprenda mais com os vídeos sobre hospedagem de site e domínio</h3>

          <div className="content-videos">
            <div className="left">
              <div className="cta-video" onClick={() => { setShowVideoModal(true); setUrlVideo('https://www.youtube.com/embed/Zgfp5oUR444') }}>
                <img className="thumb-video" src={ThumbVideo1} alt="Categoria de domínio" title="Categoria de domínio" />
                <div className="title-video">Categoria de domínio</div>
                <div className="fake-button"><RiPlayFill /></div>
              </div>
            </div>

            <div className="right">
              <div className="cta-video" onClick={() => { setShowVideoModal(true); setUrlVideo('https://www.youtube.com/embed/HYX_9FwVGx8') }}>
                <img className="thumb-video" src={ThumbVideo2} alt="Domínio e hospedagem de site" title="Domínio e hospedagem de site" />
                <div className="title-video">Domínio e hospedagem de site</div>
                <div className="fake-button"><RiPlayFill /></div>
              </div>
              <div className="cta-video" onClick={() => { setShowVideoModal(true); setUrlVideo('https://www.youtube.com/embed/GLQHQIrQISI') }}>
                <img className="thumb-video" src={ThumbVideo3} alt="Como fazer transferência de hospedagem de site?" title="Como fazer transferência de hospedagem de site?" />
                <div className="title-video">Como fazer transferência de hospedagem de site?</div>
                <div className="fake-button"><RiPlayFill /></div>
              </div>
              <div className="cta-video" onClick={() => { setShowVideoModal(true); setUrlVideo('https://www.youtube.com/embed/qBqjdvrFAjQ') }}>
                <img className="thumb-video" src={ThumbVideo4} alt="Como escolher um domínio ideal para o seu site?" title="Como escolher um domínio ideal para o seu site?" />
                <div className="title-video">Como escolher um domínio ideal para o seu site?</div>
                <div className="fake-button"><RiPlayFill /></div>
              </div>
              <div className="cta-video" onClick={() => { setShowVideoModal(true); setUrlVideo('https://www.youtube.com/embed/sXDWWJ1LUEk') }}>
                <img className="thumb-video" src={ThumbVideo5} alt="A estrutura de um domínio e como registrá-lo" title="A estrutura de um domínio e como registrá-lo" />
                <div className="title-video">A estrutura de um domínio e como registrá-lo</div>
                <div className="fake-button"><RiPlayFill /></div>
              </div>
            </div>
          </div>
        </div>
      </Videos>

      <div className="default">
        <Container>
          <h3 id="conclusao">Por que minha empresa precisa de um site?</h3>
          <p>Ter um site é um passo importante para o sucesso das organizações. Sabemos que o comportamento de consumo mudou e quase tudo gira em torno da internet.</p>
          <p>Como a maior parte da população usa smartphones, a primeira coisa que um interessado em um produto ou serviço faz é pesquisar o que ele quer comprar ou contratar nos mecanismos de busca.</p>
          <p>O custo para ter um site é relativamente baixo e traz muitos benefícios. Além de mostrar o seu trabalho na internet, uma página é uma forma eficaz de ampliar e manter contatos na rede.</p>
          <div className="w-img">
            <p>No entanto, é importante conhecer bem o seu projeto e as suas necessidades para acertar na escolha do <strong>melhor serviço de hospedagem de site disponível no mercado para você.</strong></p>
          </div>
          <p>Conheça a Task! Nosso objetivo é prover soluções em internet e comércio eletrônico para otimizar processos - gerando resultados para pessoas e organizações. Possuímos 27 anos de experiência.</p>
        </Container>
      </div>

      <Contato>
        <div className="default">
          <h3 id="contato">Fale conosco</h3>

          <FormEnvioEmail />
        </div>
      </Contato>

      <Footer />
    </>
  );
}

export default Home;