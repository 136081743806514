import React, { useEffect, useState } from 'react';
import { MdMenu, MdClose } from "react-icons/md";

import { Secao, Top, ButtonMenu, ListaMenu, ContentBanner, Logo } from './styles';

import { Container } from '../../styles/conteudo';
import UrlLogo from '../../assets/img/logo.webp'
import UrlLogoBlue from '../../assets/img/logo-blue.webp'

export default function Banner() {
  const [menu, setMenu] = useState('');
  const [menuMobile, setMenuMobile] = useState('fixed');
  const [showMenu, setShowMenu] = useState('hide');
  const [sizeScreen, setSizeScreen] = useState('');
  const [page, setPage] = useState('');

  useEffect(() => {
    getSizeScreen();
    getPage();

    window.addEventListener('scroll', (event) => {
      if (window.pageYOffset > 100) {
        setMenu('fixed');
      } else {
        setMenu('');
      }
    });
  }, []);

  const getSizeScreen = () => {
    setSizeScreen(window.screen.width)
  }

  const getPage = () => {
    setPage(window.location.pathname)
  }

  const changeShowMenu = () => {
    if (showMenu == 'show') setShowMenu('hide');
    if (showMenu == 'hide') setShowMenu('show');
  };

  const scrollTo = element => {
    const posYaction = document.getElementById(element).offsetTop;

    window.scroll({
      top: posYaction - 100,
      behavior: "smooth"
    });

    setShowMenu('hide');
  }

  return (
    <Secao id="banner" tamanhoTela={sizeScreen}>
      <Top className={(sizeScreen > 1024) ? menu : menuMobile}>
        <Container className="default">
          <>
            <img src={UrlLogoBlue} alt="Logo Task Internet" onClick={(page === '/') ? () => scrollTo('banner') : () => window.location = '/'} />

            <ButtonMenu aria-label="Menu" onClick={() => changeShowMenu()}>
              {(showMenu == 'hide') ?
                <MdMenu size="25px" />
                :
                <MdClose size="25px" />
              }
            </ButtonMenu>

            <ListaMenu className={showMenu}>
              {(page === '/') ?
                <>
                  <li onClick={() => scrollTo('sobre-dominio')}>
                    <span>Registro de domínio</span>
                  </li>
                  <li onClick={() => scrollTo('sobre-hospedagem')}>
                    <span>Hospedagem de site</span>
                  </li>
                  <li onClick={() => scrollTo('servicos')}>
                    <span>Serviços</span>
                  </li>
                  <li onClick={() => scrollTo('videos')}>
                    <span>Vídeos</span>
                  </li>
                  <li onClick={() => scrollTo('contato')}>
                    <span>Contato</span>
                  </li>
                </> :
                <li onClick={() => window.location = '/'}>
                  <span>Página Inicial</span>
                </li>
              }
            </ListaMenu>
          </>
        </Container>
      </Top>

      <div className="default banner">
        <ContentBanner>
          <h1>Tudo sobre <br /> hospedagem de site</h1>
          <h2>Saiba mais sobre registro de domínio, e-mail corporativo e como escolher a melhor hospedagem de site para sua empresa.</h2>
          <button onClick={() => window.open('https://mesgratis.task.com.br/')}>Experimente 1 mês grátis</button>
        </ContentBanner>

        <Logo src={UrlLogo} alt="Logo Task Internet" />
      </div>
    </Secao>
  );
}
