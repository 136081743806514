import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function DominioPersonalizado() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Por que ter um domínio personalizado?</h3>
          <p>É comum se perguntar a razão para ter um domínio personalizado, já que existem opções gratuitas na internet (inclusive em relação aos emails).</p>
          <p>A resposta é simples: um e-mail profissional lhe confere muito mais credibilidade do que uma conta gratuita. Além do risco de perda de privacidade, um e-mail grátis não possui um suporte especializado e humanizado para atendimento.</p>
          <p>Leia mais sobre os <a href="https://blog.task.com.br/servicos-de-e-mail-motivos-para-contratar-um-dominio-personalizado/" target="_blank">motivos para fazer um registro de domínio personalizado</a> em nosso blog.</p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default DominioPersonalizado;