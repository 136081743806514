import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Titillium+Web:200,300,400,600,700&display=swap');

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    html {
        height: 100%;
    }

    body {
        height: 100%;
        -webkit-font-smoothing: antialiased;
    }

    body, input, button, textarea {
        font-family: 'Titillium Web', sans-serif;
        letter-spacing: 1px;
    }

    .default {
        width: 70vw;
        margin: 0 auto;
        height: 100%;

        &.banner {
            width: 100vw;
            display: flex;
            justify-content: flex-end;

            @media (max-width: 1024px) {
                width: 85vw;
            }

            @media (max-width: 375px) {
                width: 95vw;
            }
        }

        @media (max-width: 1300px) {
            width: 85vw;
        }

        @media (max-width: 375px) {
            width: 95vw;
        }
    }
`;