import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  margin-bottom: 20px;

  .accordion-btn {
    background-color: #113072;
    color: #FFF;
    cursor: pointer;
    padding: 18px;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover, &.active {
      background-color: #0d2352;
    }

    .accordion-title {
      font-size: 18px;
      text-align: left;
    }
  }

  .accordion-content {
    height: ${props => props.status === 'active' ? "200px" : "0px"};
    background-color: white;
    overflow: hidden;
    transition: height 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .accordion-text {
      font-size: 18px;
      padding: 20px;
    }

    .saiba-mais {
      text-align: right;
      padding-right: 20px;
      padding-bottom: 20px;

      a {
        text-decoration: none;
        color: #f87204;
      }
    }

    @media (max-width: 1680px) {
      height: ${props => props.status === 'active' ? "230px" : "0px"};
    }
  }

  @media (min-width: 768px) {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;