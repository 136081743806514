import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function HospedagemSegura() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Hospedagem segura</h3>
          <p>Garantir uma hospedagem segura para a sua página na internet é essencial para protegê-la contra invasões. Por isso, é preciso ficar atento a algumas questões, como:</p>

          <ul>
            <li>Escolher login e senhas difíceis e fora do padrão;</li>
            <li>Manter as aplicações sempre atualizadas;</li>
            <li>Instalação da certificação SSL;</li>
            <li>Tipos e quantidade de permissões para acessar a página.</li>
          </ul>

          <p>Leia mais sobre <a href="https://blog.task.com.br/hospedagem-segura-aumentar-seguranca-site/" target="_blank">o que fazer para manter sua hospedagem segura</a> em nosso blog.</p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default HospedagemSegura;