import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function RegistroDominio() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>O que é registro de domínio?</h3>
          <p>Esse é o primeiro passo para criar uma página na internet, será o endereço do seu site: www.nomedaempresa.com.br.</p>
          <p>O registro de domínio nada mais é que um processo administrativo para informar a entidade controladora (Registro.br) que você quer ter um nome na internet.</p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default RegistroDominio;