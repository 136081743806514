import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function VariosDominios() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Porque usar vários domínios para o mesmo site?</h3>
          <p>Tão importante quanto garantir a presença digital para o seu negócio, é ter visibilidade nos meios que escolher para apresentar o trabalho que desenvolve.</p>
          <p>Para isso, é importante garantir exclusividade em alguns canais, como seu website por exemplo. Como existem muitas extensões de domínio, pode acontecer de instituições com nomes iguais (ou parecidos) criarem sites semelhantes ao seu, o que pode confundir o cliente.</p>
          <p>Quando for registrar o seu domínio, pense na possibilidade de <a href="https://blog.task.com.br/por-que-usar-varios-dominios-para-o-mesmo-site-2/" target="_blank">criar vários domínios para a sua página</a>.</p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default VariosDominios;