import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function Dominio() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Como escolher o domínio ideal para o seu site</h3>
          <p>Esse é o momento decisivo para conquistar visibilidade na rede. Pense bem na hora de <a href="https://blog.task.com.br/como-escolher-o-dominio-ideal-2/" target="_blank">escolher o nome do seu site</a>, pois depois não será possível fazer alterações (a não ser que você crie outro, o que não é recomendável).</p>
          <p>Algumas dicas podem ajudá-lo:</p>
          <ul>
            <li>Não escolha nomes muito grandes, difíceis de memorizar;</li>
            <li>Evite palavras difíceis de escrever ou pronunciar;</li>
            <li>Evite hífens e números;</li>
            <li>Opte por palavras relevantes que tenham relação com o seu negócio;</li>
            <li>Usar o nome do negócio no domínio é o mais indicado;</li>
          </ul>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Dominio;