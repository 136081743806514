import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function TiposServidor() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Tipos de servidor (Linux ou Windows)</h3>
          <p>Existem dois tipos de opções de servidores para manter um site ou qualquer tipo de aplicação do seu negócio online. Na prática, a diferença é muito pequena, mas é interessante saber que existem opções.</p>
          <p>Resumidamente, a decisão sobre qual Sistema Operacional (SO) você vai hospedar sua página, depende da escolha da linguagem utilizada (ASP Clássico, ASP .NET, PHP). Por questões de desempenho, o ideal é optar pela linguagem original daquele SO.</p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default TiposServidor;