import React, { useState } from 'react';
import { toast } from 'react-toastify';

import api from '../../services/api';

import { FormContato } from './styles';

function FormEnvioEmail() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [observacao, setObservacao] = useState('');

  const enviarForm = async (e) => {
    e.preventDefault();

    let regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (regexEmail.exec(email) === null || email.includes("ç")) {
      toast.error("E-mail inválido.");
    } else {
      if (nome !== '' && email !== '' && observacao) {
        const dados = {
          customerSite: "site tudo sobre hospedagem",
          customerName: nome,
          customerMail: email,
          customerObs: observacao,
          clientMail: "comercial@task.com.br"
        };

        setNome('');
        setEmail('');
        setObservacao('');

        toast.success("Mensagem enviada com sucesso. Em breve entraremos em contato!");

        const response = (await api.post('/contato-site', dados)).data;
      } else {
        toast.error("Preencha todos os campos para continuar.");
      }
    }
  };

  return (
    <FormContato id="form-contratar" onSubmit={enviarForm}>
      <div>
        <div className="left-form">
          <div className="form-group">
            <input id="input-nome" type="text" required value={nome} onChange={e => setNome(e.target.value)} placeholder=" " />
            <label for="input-nome">Nome</label>
          </div>
          <div className="form-group">
            <input id="input-email" type="email" required value={email} onChange={e => setEmail(e.target.value)} placeholder=" " />
            <label for="input-email">E-mail</label>
          </div>
        </div>
        <div className="right-form">
          <div className="form-group">
            <textarea id="input-observacoes" type="text" value={observacao} onChange={e => setObservacao(e.target.value)} placeholder=" "></textarea>
            <label for="input-observacoes">Observações</label>
          </div>
        </div>
      </div>
      <div className="content-button">
        <button type="submit">Enviar mensagem</button>
      </div>
    </FormContato>
  );
}

export default FormEnvioEmail;