import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function RegistrarDominio() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Como registrar um domínio?</h3>
          <p>Depois de escolher um nome de domínio e verificar se ele está disponível, é <a href="https://www.youtube.com/watch?v=gZRYDxWuYpk" target="_blank">hora de fazer o registro desse endereço</a>.</p>
          <p>Existem algumas entidades autorizadas para esse passo. No Brasil, o <a href="https://registro.br/" target="_blank">Registro.br</a> é o único responsável por  esse trabalho para extensões “.br”. A Task possui um convênio com esta entidade e pode intermediar esse processo, se preferir.</p>
          <p>Caso sua empresa seja nacional, recomendamos o uso desse tipo de extensão “.com.br”, pois a maioria das pessoas tem o hábito de digitar os endereços dessa forma no campo da URL.</p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default RegistrarDominio;