import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Dominio from './pages/Dominio';
import RegistroDominio from './pages/RegistroDominio';
import RegistrarDominio from './pages/RegistrarDominio';
import CategoriasDominio from './pages/CategoriasDominio';
import EscolherDominio from './pages/EscolherDominio';
import DominioPersonalizado from './pages/DominioPersonalizado';
import VariosDominios from './pages/VariosDominios';
import DominioCongelado from './pages/DominioCongelado';
import Hospedagem from './pages/Hospedagem';
import TiposHospedagem from './pages/TiposHospedagem';
import TiposServidor from './pages/TiposServidor';
import RecursosHospedagem from './pages/RecursosHospedagem';
import Certificado from './pages/Certificado';
import ImportanciaBackup from './pages/ImportanciaBackup';
import HospedagemSegura from './pages/HospedagemSegura';
import Glossario from './pages/Glossario';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />

      <Route path="/o-que-e-dominio" exact component={Dominio} />
      <Route path="/o-que-e-registro-de-dominio" exact component={RegistroDominio} />
      <Route path="/como-registrar-um-dominio" exact component={RegistrarDominio} />
      <Route path="/categorias-de-dominio" exact component={CategoriasDominio} />
      <Route path="/como-escolher-o-dominio-ideal-para-o-seu-site" exact component={EscolherDominio} />
      <Route path="/por-que-ter-um-dominio-personalizado" exact component={DominioPersonalizado} />
      <Route path="/por-que-usar-varios-dominios-para-o-mesmo-site" exact component={VariosDominios} />
      <Route path="/dominio-congelado" exact component={DominioCongelado} />

      <Route path="/o-que-e-hospedagem-de-site" exact component={Hospedagem} />
      <Route path="/tipos-de-hospedagem-de-site-e-email" exact component={TiposHospedagem} />
      <Route path="/tipos-de-servidor" exact component={TiposServidor} />
      <Route path="/principais-recursos-para-uma-hospedagem-de-site" exact component={RecursosHospedagem} />
      <Route path="/certificado" exact component={Certificado} />
      <Route path="/importancia-do-backup" exact component={ImportanciaBackup} />
      <Route path="/hospedagem-segura" exact component={HospedagemSegura} />

      <Route path="/glossario" exact component={Glossario} />
    </Switch >
  );
}
