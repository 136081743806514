import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function RecursosHospedagem() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Principais recursos para uma hospedagem de site</h3>
          <h4>Espaço em disco (Armazenamento)</h4>
          <p>Esse é um dos recursos mais importantes da hospedagem, pois é o que define a quantidade de armazenamento disponível para sua aplicação.</p>
          <p>Saber o tamanho aproximado do seu projeto é essencial para definir qual o tipo de hospedagem será mais adequado.</p>
          <h4>Transferência (tráfego)</h4>
          <p>Ambos os termos têm o mesmo significado. Toda ação que fazemos na internet - seja abrir um site, baixar uma foto, enviar um e-mail - consome dados.</p>
          <p>Esses dados são calculados em bytes (unidade de medida). O tráfego de dados é o número de bytes trocados na rede.</p>
          <h4>Segurança dos dados</h4>
          <p>As suas informações precisam estar seguras na web, caso algum hacker tente invadir o seu site ou aplicação. Para isso não acontecer, existem alguns mecanismos de segurança, como certificação SSL, Sistema antivírus, atualizações e backups constantes.</p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default RecursosHospedagem;