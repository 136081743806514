import styled from 'styled-components';
import { darken } from 'polished';

import presets from '../../presets/index';

import BackgroundBannerMobile from '../../assets/img/banner-mobile.webp';
import BackgroundBanner from '../../assets/img/banner.webp';

export const Secao = styled.section`
  width: 100%;
  height: 50vh;
  background: url(${props => (props.tamanhoTela <= 1024) ? BackgroundBannerMobile : BackgroundBanner});
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  
  @media (max-width: 575px) {
    height: 100vh;
  }

  @media (max-width: 1024px) {
    background-position: right;
  }

  @media (max-height: 600px) {
    .banner {
      margin-top: 30px;
    }
  }
`;

export const Top = styled.div`
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .5s;
  z-index: 1050;
  display: none;

  &.fixed {
    position: fixed;
    z-index: 1051;
    top: 0;
    left: 0;
    height: 75px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    display: block;
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    img {
      width: 125px;
      height: 49px;
      cursor: pointer;
    }
  }

  @media (max-width: 1024px) {
    & > div {
      justify-content: space-between;
    }
  }
`;

export const ButtonMenu = styled.button`
  width: 35px;
  height: 35px;
  color: #FFF;
  background-color: ${presets.light_blue};
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    display: none;
  }
`;

export const ListaMenu = styled.ul`
  display: flex;

  li {
    list-style: none;
    margin-left: 30px;
    font-size: 18px;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    &.hide {
      display: none;
    }

    &.show {
      width: 50vw;
      height: 100vh;
      background-color: #fff;
      padding: 0 20px 20px 20px;
      flex-direction: column;
      position: absolute;
      top: 75px;
      right: 0;

      @media (max-width: 575px) {
        width: 75vw;
      }

      li {
        margin: 0;
        color: #767683;
        display: block;
        font-weight: 500;
        cursor: pointer;
        font-size: 18px;
        padding: 20px 0;
        border-bottom: 1px solid #eee;
      }
    }
  }
`;

export const ContentBanner = styled.div`
  width: 28%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 30px;

  h1, h2 {
    color: #FFF;
    text-align: right;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 40px;
  }

  button {
    font-size: 1.5rem;
    font-weight: bold;
    color: #FFF;
    background-color: #f87204;
    border: 2px solid #f87204;
    padding: 15px;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
      border: 2px solid #f87204;
      background-color: ${darken(0.15, '#f87204')};
    }
  }

  @media (min-width: 1400px) and (max-width: 1600px) {
    h1 {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 1.2rem;
      margin-bottom: 30px;
    }

    button {
      font-size: 1.2rem;
      padding: 10px;
    }
  }

  @media (min-width: 1025px) and (max-width: 1366px) {
    h1 {
      font-size: 2.3rem;
    }

    h2 {
      font-size: 1.1rem;
      margin-bottom: 30px;
    }

    button {
      font-size: 1.1rem;
      padding: 10px;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin: 0;
    align-items: center;
    
    h1, h2 {
      text-align: center;
    }
  }

  @media (max-width: 575px) {
    margin: 0;
    
    h1 {
      font-size: 2.3rem;
    }

    h2 {
      font-size: 1.3rem;
    }

    button {
      font-size: 1.3rem;
      padding: 10px;
    }
  }
`;

export const Logo = styled.img`
  width: 130px;
  position: absolute;
  left: 50px;
  bottom: 10px;

  @media (max-width: 1440px) {
    width: 100px;
    left: 30px;
    bottom: 11px;
  }

  @media (max-width: 1024px) {
    right: 60px;
    bottom: 20px;
    left: auto;
  }

  @media (max-width: 575px) {
    display: none;
  }
`;