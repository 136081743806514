import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function ImportanciaBackup() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Importância do Backup</h3>
          <p>Por mais segurança que exista nos serviços de hospedagem de site, é preciso se resguardar. Sendo assim, <a href="https://blog.task.com.br/importancia-de-fazer-o-backup-do-seu-site/" target="_blank">é essencial possuir uma cópia de segurança do seu projeto</a>.</p>
          <p>Muitos provedores de hospedagem oferecem o serviço de Backup. Na Task, por exemplo, é possível contratar esse serviço para você ficar tranquilo com a segurança dos seus dados.</p>
          <h4>Contas de e-mail</h4>
          <p>O e-mail é a principal forma de troca de informações da internet e, por isso, merece atenção especial. Empresas de hospedagem oferecem o serviço de email incluído no pacote. No entanto, é preciso ficar atento a quantidade de contas ofertadas e a capacidade de cada uma.</p>
          <p>Dependendo do tamanho do seu negócio, não será preciso muitas contas. Existem planos que se adequam a sua realidade, sem ter a necessidade de pagar por recursos que não serão utilizados.</p>
          <h4>Suporte ao cliente</h4>
          <p>Muitas empresas de hospedagem de site não possuem <a href="https://www.task.com.br/suporte" target="_blank">suporte humanizado</a> ao cliente. As orientações são por meio de tutoriais ou, no máximo, por e-mail.</p>
          <p>Se você possui poucos conhecimentos, opte por uma empresa que tenha uma equipe especializada para orientar e apresentar soluções para seus problemas.</p>
          <h4>Recursos extras</h4>
          <p>Possuir <a href="https://blog.task.com.br/servicos-adicionais/" target="_blank">serviços adicionais</a> que facilitem e assegurem um bom desempenho para o seu negócio pode ser crucial na escolha da empresa que vai hospedar o seu site.</p>
          <p>A Task oferece algumas ferramentas com objetivo de tornar sua presença digital ainda mais profissional:</p>
          <ul>
            <li><a href="http://www.sitealerta.com.br/" target="_blank">SiteAlerta</a></li>
            <li><a href="http://www.atendex.com.br/" target="_blank">Atendex</a></li>
            <li><a href="https://www.task.com.br/novomail" target="_blank">NovoMail</a></li>
          </ul>
          <h4>Preços</h4>
          <p>É muito comum encontrar planos de hospedagem com valores muito baixos, no entanto, não é recomendado observar somente este quesito.</p>
          <p>Para isso, verifique tudo o que o plano oferece para ter certeza que atende a sua demanda. Afinal, não adianta nada contratar um plano baratinho que te dá direito somente a uma conta de email, se você precisa de mais.</p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default ImportanciaBackup;