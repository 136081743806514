import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function DominioCongelado() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Domínio congelado</h3>
          <p>Quando mantemos um endereço na internet (o domínio) temos que manter em dia a anualidade para que ele fique no ar. A falta de pagamento dessa tarifa pode fazer com que o seu domínio fique congelado.</p>
          <p>O congelamento bloqueia a sua página, impossibilitando que clientes e parceiros acessem seu conteúdo. Nesta situação, é importante saber o que fazer para <a href="https://blog.task.com.br/dominio-congelado/" target="_blank">descongelar o seu domínio</a>.</p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default DominioCongelado;