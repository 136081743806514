import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function Dominio() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>O que é domínio?</h3>
          <p>Originalmente, são utilizados <strong>números de IPs</strong> para localizar os endereços na web. O que tornaria tudo mais complicado se tivéssemos que decorar tantos números. Por isso, foram criados os domínios.</p>
          <p>O <strong>domínio é um nome que serve para identificar um endereço na internet</strong>. Pode ser um conjunto de computadores, aplicações ou sites que estejam na rede. Em poucas palavras, é a sua identidade na internet.</p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Dominio;