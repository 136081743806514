import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function Glossario() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Glossário</h3>
          <ul className="glossario">
            <li><strong>Backup:</strong> cópia de segurança de arquivos para outro local. Com objetivo de manter duas ou mais cópias.</li>
            <li><strong>Blog:</strong> tipo de página na internet para inclusão de conteúdo como artigos, imagens e vídeos.</li>
            <li><strong>Catch all:</strong> Conta de e-mail capaz por “pegar” todas as mensagens destinadas ao domínio.</li>
            <li><strong>Certificado SSL:</strong> protocolo de segurança para troca de informações na internet. </li>
            <li><strong>CMS:</strong> é um sistema para criação e edição de conteúdo na internet. O WordPress é um tipo de CMS. </li>
            <li><strong>cPanel:</strong> software que gerencia páginas na internet. </li>
            <li><strong>Cloud Computing:</strong> é o mesmo que computação em nuvem. É a disponibilização de arquivos em servidores virtuais. </li>
            <li><strong>Criador de Site:</strong> plataforma online para elaboração de páginas na internet. </li>
            <li><strong>Data Center:</strong> Também conhecido como centro de processamento de dados. É uma sala com um conjunto de computadores. </li>
            <li><strong>DNS:</strong> Sistema de Nomes de Domínio.</li>
            <li><strong>Domínio:</strong> é uma nomenclatura utilizada para identificar e localizar computadores na internet. </li>
            <li><strong>Domínio Adicional:</strong> São domínios “extras” vinculados aos domínios originais. </li>
            <li><strong>Domínio Estacionado:</strong> ou Parked Domain. São domínios que não tem vinculação com site ou aplicação. </li>
            <li><strong>Drupal:</strong> Sistema de gerenciamento de conteúdo.</li>
            <li><strong>E-mail:</strong> ou correio eletrônico. É um sistema que permite enviar e receber mensagens e arquivos na internet. </li>
            <li><strong>Endereço IP:</strong> É um endereço de protocolo na internet. Um número utilizado para localizar computadores na rede. </li>
            <li><strong>Espaço em Disco:</strong> É a quantidade de espaço disponível para armazenar arquivos, bancos de dados, páginas. </li>
            <li><strong>Extensão de domínio:</strong> Tudo que está escrito a direita do seu domínio. O mais comum no Brasil é o “.com.br”</li>
            <li><strong>Favicon:</strong> são imagens pequenas utilizadas para ilustração em páginas e arquivos diagramados. </li>
            <li><strong>FTP:</strong> Protocolo de Transferência de Arquivos. Ferramenta utilizada para transferência de dados na internet. </li>
            <li><strong>Hospedagem de site:</strong> serviço que permite disponibilizar páginas e aplicações online na internet.</li>
            <li><strong>Hospedagem Compartilhada:</strong> modalidade mais comum e econômica de hospedagem de site. </li>
            <li><strong>.htaccess:</strong> é um documento que define as regras de redirecionamento que permite o gerenciamento descentralizado das configurações de servidores. </li>
            <li><strong>HTML:</strong> linguagem de programação utilizada para construir páginas na internet. </li>
            <li><strong>HTTP:</strong> é um protocolo de comunicação usado na internet.</li>
            <li><strong>HTTPS:</strong> é um protocolo de comunicação usado na internet com uma camada de segurança. </li>
            <li><strong>Imap:</strong> é um tipo de protocolo para gerenciar troca de mensagem na internet. </li>
            <li><strong>Inodes:</strong> Estrutura que armazena informações acerca de arquivos e pastas.</li>
            <li><strong>IP Dedicado:</strong> é um endereço único na internet, exclusivo de uma conta de hospedagem. </li>
            <li><strong>Largura da banda:</strong> é a capacidade de download e upload de determinada conexão. </li>
            <li><strong>MySQL:</strong> sistema de gerenciamento de banco de dados. </li>
            <li><strong>Painel de Controle:</strong> ferramenta de sistema utilizada para configurar determinado software.</li>
            <li><strong>POP3:</strong> tipo de protocolo utilizado no correio eletrônico. </li>
            <li><strong>Registro.br:</strong> entidade responsável por gerenciar os domínios com extensão “.br” no Brasil. </li>
            <li><strong>Revenda de Hospedagem:</strong> tipo de serviço de hospedagem de site que permite a distribuição de recursos de hardware para mais de um domínio. </li>
            <li><strong>SEO:</strong> conjunto de ações utilizadas para melhorar o posicionamento de páginas nos buscadores da internet. </li>
            <li><strong>Servidor:</strong> é um programa de computador que tem a função de centralizar informações de uma rede de computadores.</li>
            <li><strong>Servidor Dedicado:</strong> é um computador exclusivo, configurado para acesso remoto.</li>
            <li><strong>Servidor Linux:</strong> computador configurado para acesso remoto com o Sistema Operacional Linux instalado.</li>
            <li><strong>Servidor Windows:</strong> computador configurado para acesso remoto com o Sistema Operacional Windows instalado.</li>
            <li><strong>Site:</strong> conjunto de páginas na internet acessíveis por meio de HTTP ou HTTPS.</li>
            <li><strong>SMTP:</strong> tipo de protocolo para envio de e-mails. </li>
            <li><strong>Spam:</strong>  tipo de mensagem com conteúdo publicitário distribuído em massa. </li>
            <li><strong>Subdomínio:</strong> ramificações do seu domínio principal. </li>
            <li><strong>SSD:</strong> tipo de unidade de armazenamento, semelhante ao HD. </li>
            <li><strong>SSL:</strong> Protocolo de segurança utilizado na internet. </li>
            <li><strong>SSH:</strong> Protocolo de criptografia para rede de computadores. </li>
            <li><strong>Uptime:</strong> tempo de funcionamento de um sistema sem interrupção. </li>
            <li><strong>URL:</strong> endereço de algum recurso na rede. Pode ser um site na internet ou um banco de dados da intranet, por exemplo. </li>
            <li><strong>VPS:</strong> Servidor Virtual Privado. É uma máquina virtual utilizada para hospedagem de sites e aplicações.</li>
            <li><strong>Webmail:</strong> é uma interface que permite o envio e recebimento de mensagens eletrônicas. </li>
            <li><strong>WordPress:</strong> um sistema livre de gestão de conteúdo que permite a criação de sites, lojas virtuais e blogs. </li>
            <li><strong>WHOIS:</strong> é um protocolo utilizado para identificar entidades na internet, seja um domínio, um endereço IP. </li>
          </ul>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Glossario;