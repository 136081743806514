import styled from 'styled-components';

export const Section = styled.section`
    width: 100%;
    background-color: #113072;
    padding: 50px 0 0;
`;

export const Container = styled.div`
    width: 60vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .informacoes {
        p {
            color: white;
        }

        span {
            display: block;

            &.telefone {
                font-size: 26px;
                color: #f87204;
                font-weight: 600;
                margin-top: 25px;
            }

            &.email {
                font-size: 18px;
                font-weight: 600;
                color: white;
            }
        }

        button {
            background-color: #f87204;
            color: white;
            border: 0;
            font-size: 17px;
            padding: 5px 20px;
            border-radius: 25px;
            margin-top: 25px;
            margin-right: 10px;

            svg {
                margin-right: 5px;
            }
        }
    }

    form {
        width: 50%;

        ul {
            list-style: none;
            display: flex;
            margin-top: 15px;

            li {
                a {
                    display: flex;
                    width: 35px;
                    height: 35px;
                    border: 1px solid #fff;
                    border-radius: 50%;
                    margin-right: 10px;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        p { color: white; margin-bottom: 20px; font-size: 15px; }

        .group {
            width: 100%;
            height: 50px;
            background-color: white;
            display: flex;
            justify-content: space-around;
            align-items: center;
            border-radius: 25px;

            input {
                width: 75%;
                height: 40px;
                font-size: 18px;
                border-radius: 20px;
                padding: 0 10px;
                border: 0;
            }

            button {
                width: 20%;
                height: 40px;
                cursor: pointer;
                border-radius: 20px;
                color: white;
                border: 0;
                background-color: #f87204;
                font-size: 16px;
            }

        }
    }

    @media (max-width: 1700px) {
        form {
            width: 60%;
        }
    }

    @media (max-width: 1400px) {
        form {
            .group {
                input { width: 60%; }
                button { width: 35% !important; }
            }
        }
    }

    @media (max-width: 1240px) {
        flex-direction: column;
        width: 90%;

        .informacoes {
            text-align: center;
            margin-bottom: 50px;
        }

        form {
            text-align: center;
            width: 50%;

            .group {
                width: 100%;
            }

            ul {
                justify-content: center;
            }
        }
    }

    @media (max-width: 820px) {
        width: 100%;

        form {
            text-align: center;
            width: 70%;

            .group {
                width: 100%;
            }

            ul {
                justify-content: center;
            }
        }
    }

    @media (max-width: 588px) {
        form {
            text-align: center;
            width: 90%;

            .group {
                width: 100%;
            }

            ul {
                justify-content: center;
            }
        }
    }
    
`;


export const Copy = styled.div`
    width: 100%;
    height: 75px;
    background-color: #0d2352;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);

    p {
        span {
            color: white;
        }
    }
`;