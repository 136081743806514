import styled from 'styled-components';
import presets from '../../presets/index';

export const Duvidas = styled.section`
  padding-bottom: 50px;

  h3 {
    color: ${presets.text_title};
    font-size: 30px;
    margin-bottom: 25px;
  }

  h4 {
    color: ${presets.text_title};
    font-size: 25px;
    margin-bottom: 10px;
  }
`;

export const Collapse = styled.div`
  margin-bottom: 50px;

  &:last-child {
    margin: 0;
  }
`;

export const ContentCollapse = styled.div`
  display: flex;

  .column {
    display: flex;
    flex-direction: column;
    width: 50%;

    &:first-child {
      margin-right: 20px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    .column {
      width: 100%;

      &:first-child {
        margin-right: 0;
      }
    }
  }
`;

export const Servicos = styled.section`
  width: 100%;
  background-color: ${presets.bg_section};
  padding: 25px 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    padding: 25px 0;

    h3 {
      color: ${presets.text_title};
      font-size: 30px;
      margin-bottom: 25px;
    }

    .wrap-painels {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;
    }

    @media (max-width: 1366px) {
      h2 {
          font-size: 30px;
      }
    }

    @media (max-width: 1024px) {
      width: 100%;

      .wrap-painels {
        flex-wrap: wrap;
      }
    }

    @media (max-width: 768px) {
     .wrap-painels {
        gap: 15px;
      }
    }

    @media (max-width: 575px) {
      padding: 0;

      .wrap-painels {
        justify-content: center;
      }
    }

    @media (max-width: 425px) {
      .wrap-painels {
        width: 100%;
        justify-content: center;
      }
    }
  }
`;

export const Painel = styled.div`
  width: 25%;
  border-radius: 15px 15px 0 0 ;
  display: flex;
  flex-direction: column;
  box-shadow: ${presets.shadow};
  border-radius: 15px 15px 5px 5px;

  img {
    width: 100%;
    border-radius: 10px 10px 0 0;
  }

  & > .title {
    width: 100%;
    height: 30px;
    font-size: 18px;
    background-color: ${presets.blue};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: ${presets.blue} transparent transparent transparent;
      position: absolute;
      top: 30px;
      left: 20px;
    }

    @media (max-width: 1440px) {
      font-size: 15px;
    }
  }

  & > .content {
    background-color: ${presets.bg_content_painel};
    width: 100%;
    height: 365px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 0 5px 5px;

    p {
      color: ${presets.text_content};
      height: 53%;
      font-size: 18px;
    }

    hr {
      width: 40%;
      height: 1px;
      border-bottom: 1px solid ${presets.text_servicos};
      opacity: 0.5;
      margin: 25px 0;
    }

    span {
      height: 25px;
      font-size: 18px;
      font-weight: 700;
      color: ${presets.blue};
      letter-spacing: 1px;
      display: flex;
      align-items: center;
    }

    button {
      font-size: 18px;
      background-color: ${presets.orange};
      width: 100%;
      color: white;
      border: 0;
      padding: 0 20px;
      height: 40px;
      margin-top: 25px;
      border-radius: 25px;
      cursor: pointer;
    }
  }

  @media (max-width: 1440px) {
    .content {
      p, button {
        font-size: 13px;
      }
    }
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    .content {
      height: 297px;
      padding: 20px;

      button {
        width: 100%;
        padding: 0 5px;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 1024px) {
    width: 48%;

    .content {
      height: 455px;
    }
  }

  @media (max-width: 575px) {
    width: 400px;

    img {
      width: 400px;
      height: 267px;
    }

    .content {
      height: 257px;

      p {
        height: 40%;
      }
    }
  }

  @media (max-width: 425px) {
    width: 300px;
    
    img {
      width: 300px;
      height: 200px;
    }
  }

  @media (max-width: 340px) {
    width: 280px;
    
    img {
      width: 280px;
      height: 187px;
    }
  }
`;

export const Videos = styled.section`
  padding-top: 40px;

  h3 {
    color: ${presets.text_title};
    font-size: 30px;
    margin-bottom: 25px;
  }

  .content-videos {
    display: flex;
    justify-content: space-between;

    .left, .right {
      width: 50%;

      .cta-video {
        cursor: pointer;
        position: relative;

        .thumb-video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        .title-video {
          width: 100%;
          height: 60px;
          background-color: ${presets.light_blue};
          color: white;
          padding: 0 20px;
          position: absolute;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          opacity: 0.8;
        }

        .fake-button {
          background-color: white;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          top: calc(50% - 40px);
          left: calc(50% - 40px);
          opacity: 0.5;

          svg {
            color: ${presets.light_blue};
          }
        }
      }
    }
    
    .left {
      .cta-video {
        width: 100%;
        height: 462px;

        .title-video {
          font-size: 20px;
        }

        .fake-button {
          width: 80px;
          height: 80px;

          svg {
            font-size: 50px;
          }
        }
      }
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .cta-video {
        width: calc(50% - 10px);
        height: auto;

        .title-video {
          font-size: 15px;
        }

        .fake-button {
          width: 50px;
          height: 50px;

          svg {
            font-size: 30px;
          }
        }
      }
    }

    @media (min-width: 1025px) {
      .left {
        margin-right: 20px;
      }
    }

    @media (max-width: 1024px) {
      flex-direction: column;

      .left, .right {
        width: 100%;

        .cta-video {
          height: 300px;

          .title-video {
            font-size: 15px;
          }

          .fake-button {
            width: 50px;
            height: 50px;

            svg {
              font-size: 30px;
            }
          }
        }
      }

      .left {
        margin-bottom: 20px;

        .cta-video {
          width: 100%;
        }
      }
    }

    @media (max-width: 575px) {
      .left, .right {
        width: 100%;

        .cta-video {
          width: 100%;
        }
      }
    }
  }
`;

export const Contato = styled.section`
  background-color: ${presets.bg_section};
  padding: 25px 0 50px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    color: ${presets.text_title};
    font-size: 30px;
    margin-bottom: 25px;
  }
`;