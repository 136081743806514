import styled from 'styled-components';

export const Container = styled.div`
    flex: 8;
    margin-top: 35px;
    padding-bottom: 50px;

    @media (max-width: 1024px) {
        margin-left: 0;
    }

    h3 {
        color: #333;
        font-size: 30px;
        margin-bottom: 25px;
    }

    h4 {
        color: #333;
        font-size: 20px;
        margin-top: 25px;
    }

    h5 {
        color: #333;
        font-size: 18px;
        margin-top: 25px;
    }
    
    p {
        color: #444;
        display: block;
        font-weight: normal;
        text-align: justify;
        margin: 30px 0;
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 25px;

        &:last-child {
            margin-bottom: 0;
        }

        ul {
            margin-top: 25px;

            
        }

        &.destaque {
            color: #444;
            font-weight: 600;
        }

        a {
            color: #1868dd;
            text-decoration: none;
        }
    }

    ul {
        padding-left: 25px;

        &.glossario {
            li {
                margin-top: 10px;
            }
        }

        li {
            color: #555;
            
            strong {
                font-size: 18px;
            }

            a {
                color: #1868dd;
                text-decoration: none;
            }
        }
    }

    .w-img {
        margin: 20px 0;
        display: flex;
        align-items: center;

        img {
            width: 40%;
            margin-right: 30px; 
        }

        p {
            font-size: 17px;
            font-weight: 400;
        }

        ul {
            margin-top: 10px;
            li {
                font-size: 16px;
            }
        }

        @media (max-width: 425px) {
            flex-direction: column;
        }
    }
`;