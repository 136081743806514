import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function Hospedagem() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>O que é hospedagem de site?</h3>
          <p>A hospedagem de site é uma prestação de serviço online para armazenar, com segurança, qualquer tipo de informação (sites, aplicações, banco de dados, contas de email, arquivos, imagens) em servidores devidamentes configurados para este fim.</p>
          <p>Esse “espaço” designado para o seu conteúdo, ficará online 24 horas por dia, podendo ser acessado de qualquer dispositivo conectado à internet.</p>
          <p>No caso de um website, por exemplo, escolher uma empresa que fornece uma hospedagem de site segura, confiável e com bom desempenho, garante uma experiência adequada para os usuários que vão acessar a sua página.</p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Hospedagem;