const presets = {
  text_title: '#333',
  text_content: '#444',

  bg_section: '#ebebec',
  light_blue: '#2567f5',
  blue: '#113072',
  dark_blue: '#0d2352',
  orange: '#f87204',
  white: '#FFF',

  bg_content_painel: '#f9f9f9',
  shadow: '0 .125rem .25rem rgba(0,0,0,.075)',
};

export default presets;