import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  z-index: 2010;
  position: fixed;
  top: calc(50% - 250px);
  left: calc(50% - 400px);
  width: 800px;
  height: 500px;
  padding: 20px;
  border-radius: 10px;

  .cta-close-modal {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -15px;
    right: -15px;
    border-radius: 50%;
    border: 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

  @media (max-width: 800px) {
    width: 90vw;
    height: 500px;
    top: calc(50% - 250px);
    left: calc(50% - 45vw);
  }
  
  @media (max-width: 500px) {
    height: 300px;
    top: calc(50% - 150px);
  }
`;

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
`;

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2000;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
`;