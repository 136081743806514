import React from 'react';
import { RiCloseLine } from 'react-icons/ri';

import { Backdrop, Container, Wrap } from './styles';

function ModalVideo({ closeModal, videoUrl }) {
  return (
    <Wrap>
      <Backdrop onClick={closeModal}></Backdrop>
      <Container>
        <button className="cta-close-modal" onClick={closeModal}><RiCloseLine /></button>
        <iframe
          width="100%"
          height="100%"
          src={videoUrl}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </Container>
    </Wrap>
  );
}

export default ModalVideo;