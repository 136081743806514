import React, { useState, useEffect } from 'react';

import { Section, Container, Copy } from './styles';
import { FaWhatsapp, FaRegComments, FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaYoutube } from 'react-icons/all';

import api from '../../services/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Footer() {
    const [email, setEmail] = useState('');
    const [ano, setAno] = useState('');

    useEffect(() => {
        getYear();
    }, []);

    const abrirChat = () => {
        var url = "https://task.com.br/chat.html";
        var width = 500;
        var height = 400;
        var left = 99;
        var top = 99;
        window.open(url, 'janela', 'width=' + width + ', height=' + height + ', top=' + top + ', left=' + left + ', scrollbars=yes, status=no, toolbar=no, location=no, directories=no, menubar=no, resizable=no, fullscreen=no');
    }

    const irWhats = () => {
        window.open('https://api.whatsapp.com/send?phone=553184253018', '_blank');
    }

    const submitNews = async (e) => {
        e.preventDefault();

        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (regex.exec(email) != null) {
            const response = await api.post('/cadastra-email', { email });
            if (response.data) {
                toast.success("Obrigado por cadastrar seu e-mail!", { position: toast.POSITION.BOTTOM_RIGHT });
                setEmail('');
            }
        } else {
            toast.error("O e-mail não está no padrão correto.", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    const getYear = () => {
        const date = new Date();

        setAno(date.getFullYear());
    }

    return (
        <Section id="Footer">
            <Container>
                <div className="informacoes">
                    <p>
                        Rua Domingos Vieira, 348 cj 1501<br />
                        30150-242 - Belo Horizonte - MG
                    </p>
                    <span className="telefone">
                        (31) 3123-1000
                    </span>
                    <span className="email">falecom@task.com.br</span>
                    <button onClick={() => abrirChat()}>
                        <FaRegComments color="#fff" size="17px" />
                        Chat
                    </button>
                    <button onClick={() => irWhats()}>
                        <FaWhatsapp color="#fff" size="17px" />
                        WhatsApp
                    </button>
                </div>
                <form onSubmit={submitNews}>
                    <p>
                        Receba notícias e novidades sobre presença de sua empresa na internet
                    </p>
                    <div className="group">
                        <input placeholder="Digite seu e-mail" value={email} onChange={e => setEmail(e.target.value)} />
                        <button>Inscreva-se</button>
                    </div>
                    <ul className="social">
                        <li><a href="https://www.facebook.com/TaskInternet/" aria-label="Ir para facebook" target="_blank" ><FaFacebookF color="#fff" size="16px" /></a></li>
                        <li><a href="https://twitter.com/TaskInternet" aria-label="Ir para twitter" target="_blank"><FaTwitter color="#fff" size="16px" /></a></li>
                        <li><a href="https://www.linkedin.com/company/taskinternet/" aria-label="Ir para linkedin" target="_blank"><FaLinkedinIn color="#fff" size="16px" /></a></li>
                        <li><a href="https://www.instagram.com/task_internet/" aria-label="Ir para instagram" target="_blank"><FaInstagram color="#fff" size="16px" /></a></li>
                        <li><a href="https://www.youtube.com/user/TaskInternet" aria-label="Ir para youtube" target="_blank"><FaYoutube color="#fff" size="16px" /></a></li>
                    </ul>
                </form>
            </Container>
            <Copy>
                <p>© {ano} <span>Task Internet</span> - Todos os direitos reservados</p>
            </Copy>
        </Section>
    );
}
