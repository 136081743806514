import React, { useState } from 'react';
import { FaChevronRight, FaChevronDown, FaArrowRight } from "react-icons/fa";

import { Container } from './styles';

function Accordion({ title, content, link }) {
  const [active, setActive] = useState("");

  const toggleAccordion = () => {
    setActive(active === "" ? "active" : "");
  };

  return (
    <Container status={active}>
      <button className={`accordion-btn ${active}`} onClick={toggleAccordion}>
        <p className="accordion-title">{title}</p>
        {(active === "active") ? <FaChevronDown /> : <FaChevronRight />}
      </button>
      <div className="accordion-content">
        <p className="accordion-text">{content}</p>

        <div className="saiba-mais">
          <a href={link}>Saiba Mais <FaArrowRight /></a>
        </div>
      </div>
    </Container>
  );
}

export default Accordion;