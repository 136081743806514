import React from 'react';

import { Container } from '../../styles/conteudo';

import Banner from '../../components/Banner';
import Footer from '../../components/Footer';

function CategoriasDominio() {
  return (
    <>
      <Banner />
      <div className="default">
        <Container>
          <h3>Categorias de domínio</h3>
          <p>Além da extensão mais comum “.com.br”, existem outras <a href="https://registro.br/dominio/categorias/" target="_blank">categorias de domínios</a> que podem ser utilizadas de acordo com o seu tipo de negócio.</p>
          <p>Essas extensões podem ser usadas por pessoas físicas, jurídicas, cidades, tipos de profissão. Algumas costumam apresentar restrições de uso de acordo com a categoria.</p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default CategoriasDominio;